// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//@import 'node_modules/bootstrap/scss/bootstrap';
//@import 'node_modules/bootstrap-vue/src/index.scss';
